

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width:100%;
  font-family: 'Source Sans 3', sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #555;
}

/* App.css */

.App .content {
  transition: filter 0.3s ease;
}

.App .content.blur {
  filter: blur(5px); /* Blur effect for the background content */
}
